import React from 'react'

const Privacy = () => {
    return (
        <div className='privacy-policy'>
            {/*Start Hero Section*/}
            <section className="hero-privacy">
                <div className="container text-center">
                    <div className="hero-content">
                        <span>Privacy Policy</span>
                        <h1>Font Arts Keyboard</h1>
                        <p>
                            With the given policy, we notify the laws, rules, limitations, conditions,
                            <br />
                            and privacy policy when you use our app.
                        </p>
                    </div>
                </div>
            </section>
            {/*End-Hero-Section*/}
            {/*Start Privacy Policy Section*/}
            <section className="privacy-policy">
                <div className="container">
                    <div className="privacy-policy-content">
                        <div className="mb-30">
                            <h2>1. Privacy Policy</h2>
                            <p>
                                This page's privacy policy applies to all applications published by <strong>“Vasundhara infotech LLP”</strong>. We announce that the data we gather from our users will be bound to our company under the terms of this policy. We utilize your information to deliver relevant services and an engaging user experience. This policy outlines the procedures for collecting, using and disclosing any sort of data obtained from app users.
                            </p>
                            <p>We take the security and privacy of your data seriously. This privacy statement explains the types of data that <strong>“Font Arts Keyboard”</strong> and its parent company, affiliates, and subsidiaries (collectively, "we," "us," or "our") acquire, how we use that data, and the parties with whom we share it. This privacy statement is applicable to your access to and use of <strong>“Font Arts Keyboard”</strong> and any subsequent versions, updates, or changes thereof; it also covers any information, goods, or services that may be provided in connection with the App.</p>
                        </div>

                        <div className="mb-30">
                            <h2>2. There are the following sections in this privacy policy</h2>
                            <h3>Information That You Give Us.</h3>
                            <p>We only have access to the data you voluntarily provide us. We gather information, for instance, whether you open an account, subscribe to a service, engage in interactive elements of that service, sign up for a newsletter or email list, take part in a survey, contest, promotion, or event, or otherwise get in touch with us.</p>
                            <h3>A) Types of information we may collect from you include:</h3>
                            <h4>(a) Account Information:</h4>
                            <p>Your name, email address, password, postal address, phone number, subscription information, and any other details you want to provide may be collected in relation to your accounts.</p>

                            <h4>(b) Transaction Information:</h4>
                            <p>Regarding any goods and services you purchase from us, we may gather information. Although we do not collect or store complete credit card details and all transactions are handled by our third-party payment processor, we may also get and retain some limited payment information from you, such as the kind of payment card.</p>

                            <h4>(c) Other Information:</h4>
                            <p>You Choose to Provide, such as when you take part in a poll, contest, promotion, or interactive portion of the Services or when you ask for customer or technical support.</p>

                            <h3>B) Information We Collect Automatically</h3>
                            <p>The types of information we may automatically gather about you when you use or use the Services include:</p>

                            <h4>(a) Log Information:</h4>
                            <p>Your Internet Protocol ("IP") address, operating system, browser type and language, referring URLs, access times, pages viewed, links clicked, and other information about your activity on the Services are automatically logged by our servers when you visit the Services.</p>

                            <h4>(b) Mobile Device Information:</h4>
                            <p>We gather details on the hardware model, operating system, and version of the mobile device you use to access or use the Services, as well as specific device identifiers, information about your mobile network, and details about how you use the Services. To make it easier for you to use specific features of the Services, we may additionally get information about the location of your device and access and gather data from a few native programmes on your device (such as the camera, photo album, and phonebook applications). Please read "Your Choices" below for additional details on how to manage the collection of location data and/or our access to other programmes on your device.</p>

                            <h4>(c) Information Collected by Cookies and Other Tracking Technologies:</h4>
                            <p>When you engage with our Services, we and our service providers use a variety of tracking technologies, such as cookies and web beacons, to gather information about you, such as details about your browsing and purchasing habits. Cookies are little data files that are saved to your device's memory or hard disc and allow us analyse user behaviour, count visits, and improve the Services and your experience. Web beacons are tiny electronic pictures that may be used in emails or the Services to distribute cookies, track website activity, and measure the success of advertising campaigns. Please see "Your Choices" below for additional details on cookies and how to turn them off.</p>

                            <h3>C) Information We Collect From Other Sources:</h3>
                            <p>Along with information we directly collect, we might also get information about you from other sources. For instance, if you publish content to our pages or feeds on third-party social media sites or if you establish or log into your account using credentials from a third-party website, we may gather information about you from such actions.</p>
                        </div>

                        <div className="mb-30">
                            <h2>3. Use of Information</h2>
                            <p>We might utilise data about you for a variety of things, like:</p>
                            <ul>
                                <li>(a) Provide, maintain, and enhance the Services;</li>
                                <li>(b) Manage your account and send you information relating to it, such as confirmations, updates, technical notices, security alerts, support messages, and administrative communications;</li>
                                <li>(c) Address your remarks, inquiries, and requests and offer customer support;</li>
                                <li>(d) Keep in touch with you on the Services, deals, polls, events, and other news and information we believe you'll find interesting;</li>
                                <li>(f) Identify, look into, and stop fraudulent transactions and other illegal activity in order to safeguard our rights and property as well as those of others;</li>
                                <li>(e) Monitor and analyze trends, usage, and activities related to the Services;</li>
                                <li>(g) Customize and enhance the Services and offer features, information, and ads based on your preferences or profile;</li>
                                <li>(h) Coordinate contests, sweepstakes, and promotions; process and deliver entries, and deliver prizes.</li>
                                <li>(I) Mix or link the information we get from other sources in order to better understand your needs and offer you a better experience; and</li>
                                <li>(j) Complete any further task for which the information was gathered.</li>
                            </ul>
                        </div>

                        <div className="mb-30">
                            <h2>4. Sharing of Information</h2>
                            <p>We might disclose information about you in the ways listed below or in other ways specified in this privacy statement:</p>
                            <ul>
                                <li>(a) With suppliers, advisers, and other service providers that require access to such information to complete tasks or provide services on our behalf.</li>

                                <li>(b) Certain information you give, including as your name, images, and other information you choose to supply, may be displayed publicly when you communicate with us on third-party social media sites or participate in the interactive parts of the Services.</li>

                                <li>(c) We may disclose your information to third parties and service providers who deliver goods and services to us, assist us in reaching out to you through marketing and advertising campaigns (including co-branded campaigns), or deliver goods and services to you directly.</li>

                                <li>(d) We also offer information and content to academics and research partners so they can carry out studies that advance scholarship and innovation that benefit our company or mission, as well as research that fosters discovery and innovation on issues related to public interest, general social welfare, technological development, health, and well-being.</li>

                                <li>(e) If we believe disclosure is necessary or permitted by any relevant law, rule, regulation, or legal process, in response to a request for information.</li>

                                <li>(f) In connection with, or during the course of, any merger, acquisition, sale of assets or other business, other change of control transaction, or financing; between and among us and any current or future parent company, subsidiary, and/or affiliated company; and With your permission or under your direction.</li>

                                <li>(g) We may also distribute de-identified or aggregated data, which cannot be used to reasonably identify you.</li>
                            </ul>
                        </div>

                        <div className="mb-30">
                            <h2>5. Social Sharing Features</h2>
                            <p>You can share actions you do on the Services with other media by using social sharing features and other integrated tools, such as the Facebook and Twitter buttons (such as "Like" or "Tweet"), which are available on the Services. Depending on the settings you establish with the provider of the social sharing feature, your use of such services permits the sharing of information with your friends or the general public. Please refer to the privacy policies of the organisations that provide these features for more details regarding the objectives and scope of data collection and processing in connection with those features.</p>
                        </div>

                        <div className="mb-30">
                            <h2>6. Security</h2>
                            <p>We place a high priority on the security of your information. We put in place and uphold reasonable security measures designed to prevent unauthorized access, use, disclosure, modification, or destruction of the data we collect and keep. Please be aware, nonetheless, that despite our best efforts, no data security measures, method of Internet transmission, or technique of physical or electronic storage are 100% secure.</p>
                            <p>You acknowledge and accept that you do so at your own risk and that we cannot completely ensure the security of any information you send to, through, or on the Services or via the Internet. You are in charge of maintaining the secrecy of any passwords we have given you that give you access to your account. Please don't tell anyone your password.</p>
                        </div>

                        <div className="mb-30">
                            <h2>7. Sensitive Information</h2>
                            <ul>
                                <li>android.permission.SYSTEM_ALERT_WINDOW: This app requires permission to show alerts on top of other apps, such as floating notifications or chat heads. Please enable this permission in your device's settings.</li>
                                <li>android.permission.RECORD_AUDIO: This app requires permission to access your microphone in order to enable voice recording features. Please allow this permission to proceed.</li>
                                <li>android.permission.READ_MEDIA_IMAGES: This app requires permission to access your photos and images to display them within the app. Please grant access to your media library.</li>
                                <li>android.permission.READ_EXTERNAL_STORAGE: This app requires permission to read files stored on your device. Please allow access to external storage so we can retrieve the files you need.</li>
                                <li>android.permission.READ_USER_DICTIONARY: This app requires permission to read your device’s user dictionary in order to enhance typing suggestions. Please grant this access.</li>
                                <li>android.permission.WRITE_USER_DICTIONARY: This app requires permission to write to your user dictionary in order to improve word suggestions and add custom words. Please allow us to make these changes.</li>
                            </ul>
                        </div>

                        <div className="mb-30">
                            <h2>8. Advertising and Analytics Services Provided by Others</h2>
                            <p>We might let other people perform analytics work and sell ads on our behalf across the Internet. These organisations may gather information about your use of the Services and other websites using cookies, web beacons, and other technologies. This information may include your IP address, web browser, pages viewed, time spent on pages, links clicked, and conversion data. We and others may use this information for a variety of purposes, such as data tracking and analysis, gauging the popularity of particular content, delivering advertisements and content catered to your interests on the Services and other websites, and getting a better understanding of your online behaviour.</p>
                            <p>If you prefer not to get tailored advertising, we and our third-party partners will cease sending you personalised ads. To stop receiving personalised ads, go to "Settings." Please be aware that while the quantity of advertisements you view won't change, their relevance to you might.</p>
                        </div>

                        <div className="mb-30">
                            <h2>9. Your Choices</h2>
                            <h3>(a) Account Information</h3>
                            <p>By signing into your online account or sending us an email at coloringpages2018@gmail.com, you can update, edit, or amend information about yourself at any time. Please write us at coloringpages2018@gmail.com if you want to deactivate your account, but keep in mind that we may still keep your information on file if obliged to do so by law or for proper business needs.</p>

                            <h3>(b) Location Information</h3>
                            <p>When you use our mobile applications, we may, with your permission, acquire information about your precise location. By adjusting the settings on your mobile device, you can stop the collection of this information at any time. However, if you do so, some functionalities of our mobile applications could cease working.</p>

                            <h3>(c) Native Applications on Mobile Devices</h3>
                            <p>Certain native applications on your mobile device, such as the camera and photo storage app and the phonebook application, can be needed in order to use some functions of our mobile applications. In order to access the applications and gather information if you choose to utilize these features, we will first obtain your permission. The settings on your device allow you to revoke your consent at any moment.</p>

                            <h3>(d) Cookies</h3>
                            <p>Most web browsers are configured by default to allow cookies. You may typically opt to set your browser to delete or reject browser cookies if you'd like. Please note that the availability and operation of the Services may be impacted if you choose to delete or reject cookies.</p>

                            <h3>(e) Promotional Communications</h3>
                            <p>By following the instructions in such communications or sending us an email at coloringpages2018@gmail.com, you can choose not to receive promotional communications from us. If you choose not to receive promotional emails from us, we may still communicate with you regarding your account or our ongoing business relationships.</p>

                            <h3>(f) Push Notifications</h3>
                            <p>We may use your permission to deliver push notifications or alerts to your mobile device that are both promotional and non-promotional. Whenever you choose, you can disable these messages by adjusting the notification settings on your mobile device.</p>
                        </div>

                        <div className="mb-30">
                            <h2>10. Children’s Privacy</h2>
                            <p>Children under the age of 13 are not permitted to use the Services. Children under the age of 13 must first get legally binding parental or guardian consent before using the Services for any reason (both for themselves and on your behalf). Without such consent, we never knowingly collect personal data from anyone under the age of 13. If you think we may be in possession of personal information about anyone under the age of 13 without their consent, please get in touch with us. We'll do everything we can to get rid of it as soon as we can.</p>
                        </div>

                        <div className="mb-30">
                            <h2>11. Links To Other Sites</h2>
                            <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the privacy policy of these websites. I have no control over and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services.</p>
                        </div>

                        <div className="mb-30">
                            <h2>12. Changes to this privacy policy</h2>
                            <p>We reserve the right to modify our privacy statement in light of the services we offer. Please check back often as the page will be updated with any changes to the policy. Depending on the situation, we might give you additional sorts of notice of updates or changes.</p>
                            <p>You can stop using any of our services and remove our applications if you disagree with our privacy policy. You will nonetheless keep using any of our applications, indicating your acceptance of our policy. Please be aware that neither our staff nor our representatives have the power to alter any of our policies.</p>
                        </div>

                        <div className="mb-30">
                            <h2>13. Contact Information</h2>
                            <p>Get in touch with us regarding our privacy statement by sending an email to the following address :</p>
                            <span>Vasundhara infotech LLP</span>
                            <span>Email : <a href="mailto:coloringpages2018@gmail.com">coloringpages2018@gmail.com</a></span>
                            <p>Thank You</p>
                        </div>
                    </div>
                </div>
            </section>
            {/*End Privacy Policy Section*/}
        </div>
    )
}

export default Privacy;